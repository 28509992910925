/* eslint-disable import-newlines/enforce ,multiline-ternary */
import { useState, useEffect } from "react";

import { ThngDocument, ProductDocument } from "evrythng";

import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Alert,
} from "@mui/material";
import ThemeCustomization from "./themes";

import config from "./config";

import Header from "./components/Header";
import Footer from "./components/Footer";
import FooterContent from "./components/FooterContent";
import DataTable from "./components/DataTable";
import DebugInfo from "./components/DebugInfo";

import { productCloudAxiosRequest } from "./api";

import { defaultDebugInfoMap, ProductCloudFieldNames } from "./data";

import formatDate, {
  generateDateFromString,
  generateShortStringFromDate,
} from "./utils/formatDate";
import { ProductInfoFields } from "./types";

function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const thngId = queryParameters.get("thng");
  const productId = queryParameters.get("product");
  const token = queryParameters.get("token");

  const [pageLoadTime, setPageLoadtime] = useState<Date | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [batchInfoData, setBatchInfoData] = useState(defaultDebugInfoMap);

  const getThng = async () => {
    try {
      if (token && thngId && productId) {
        setLoading(true);
        setError(null);

        const apiCredentials = {
          apiKey: token as string,
          apiVersion: config.apiSettings.version,
          region: config.apiSettings.region,
        };

        const [thngResult, productResult] = await Promise.allSettled([
          productCloudAxiosRequest({
            credentials: apiCredentials,
            url: `/thngs/${thngId}`,
            method: "get",
          }),
          productCloudAxiosRequest({
            credentials: apiCredentials,
            url: `/products/${productId}`,
            method: "get",
          }),
        ]);

        if (thngResult.status === "fulfilled") {
          addThngInfoToBatchInfo(thngResult.value as ThngDocument);
        } else if (thngResult.status === "rejected") {
          // setError("Invalid Thng Id.");
          setError("(error: API call error)");
        }

        if (productResult.status === "fulfilled") {
          addProductInfoToBatchInfo(productResult.value as ProductDocument);
        } else if (productResult.status === "rejected") {
          // setError("Invalid Product Id.");
          setError("(error: API call error)");
        }
      } else {
        setError("(error: issue with parameters)");
      }
      setLoading(false);
    } catch (thngError) {
      setError("Failed to get Batch Information.");
      setLoading(false);
    }
  };

  const addThngInfoToBatchInfo = (thngData: ThngDocument) => {
    setBatchInfoData((prevBatchInfo) => {
      prevBatchInfo.set(ProductInfoFields.EXPIRY_DATE, {
        ...prevBatchInfo.get(ProductInfoFields.EXPIRY_DATE)!,
        value: generateShortStringFromDate(
          generateDateFromString(thngData?.customFields[ProductCloudFieldNames[ProductInfoFields.EXPIRY_DATE]])
        ),
      });

      prevBatchInfo.set(ProductInfoFields.MANUFACTURING_DATE, {
        ...prevBatchInfo.get(ProductInfoFields.MANUFACTURING_DATE)!,
        value: generateShortStringFromDate(
          generateDateFromString(
            thngData?.customFields[ProductCloudFieldNames[ProductInfoFields.MANUFACTURING_DATE]]
          )
        ),
      });

      prevBatchInfo.set(ProductInfoFields.BATCH_ID, {
        ...prevBatchInfo.get(ProductInfoFields.BATCH_ID)!,
        value:
          thngData?.identifiers[ProductCloudFieldNames[ProductInfoFields.BATCH_ID]]?.toUpperCase() || "Empty Batch ID",
      });

      return new Map(prevBatchInfo);
    });
  };

  const addProductInfoToBatchInfo = (productData: ProductDocument) => {
    setBatchInfoData((prevBatchInfo) => {
      prevBatchInfo.set(ProductInfoFields.PRODUCT_NAME, {
        ...prevBatchInfo.get(ProductInfoFields.PRODUCT_NAME)!,
        value: productData?.name || "No Product Name",
      });

      prevBatchInfo.set(ProductInfoFields.BRAND_NAME, {
        ...prevBatchInfo.get(ProductInfoFields.BRAND_NAME)!,
        value: productData?.brand || "No Brand",
      });

      prevBatchInfo.set(ProductInfoFields.GTIN, {
        ...prevBatchInfo.get(ProductInfoFields.GTIN)!,
        value: productData?.identifiers?.[ProductCloudFieldNames[ProductInfoFields.GTIN]] || "No GTIN",
      });

      prevBatchInfo.set(ProductInfoFields.MANUFACTURING_NAME_ADDRESS, {
        ...prevBatchInfo.get(ProductInfoFields.MANUFACTURING_NAME_ADDRESS)!,
        value:
          productData?.customFields?.[ProductCloudFieldNames[ProductInfoFields.MANUFACTURING_NAME_ADDRESS]] ||
          "No Manufacturing Address",
      });

      // If manufacturer license number is present
      if (productData?.customFields?.[ProductCloudFieldNames[ProductInfoFields.MANUFACTURING_LICENSE_NUMBER]]) {
        //  Add manufactuer license number to product info
        prevBatchInfo.set(ProductInfoFields.MANUFACTURING_LICENSE_NUMBER, {
          ...prevBatchInfo.get(ProductInfoFields.MANUFACTURING_LICENSE_NUMBER)!,
          value:
            productData?.customFields?.[ProductCloudFieldNames[ProductInfoFields.MANUFACTURING_LICENSE_NUMBER]]
        });

        // Add import license but make it empty if not present
        prevBatchInfo.set(ProductInfoFields.IMPORT_LICENSE_NUMBER, {
          ...prevBatchInfo.get(ProductInfoFields.IMPORT_LICENSE_NUMBER)!,
          value: productData?.customFields?.[ProductCloudFieldNames[ProductInfoFields.IMPORT_LICENSE_NUMBER]] || ""
        });
        // Else check if import license number is present
      } else if (productData?.customFields?.[ProductCloudFieldNames[ProductInfoFields.IMPORT_LICENSE_NUMBER]]) {
          //  If so add import license number to product info
        prevBatchInfo.set(ProductInfoFields.IMPORT_LICENSE_NUMBER, {
          ...prevBatchInfo.get(ProductInfoFields.IMPORT_LICENSE_NUMBER)!,
            value:
              productData?.customFields?.[ProductCloudFieldNames[ProductInfoFields.IMPORT_LICENSE_NUMBER]]
          });

        // Add empty manufacturer license
        prevBatchInfo.set(ProductInfoFields.MANUFACTURING_LICENSE_NUMBER, {
          ...prevBatchInfo.get(ProductInfoFields.MANUFACTURING_LICENSE_NUMBER)!,
            value: ""
          });
        //  Else if both not present
        } else {
          // Set manufacturing license number to error message
        prevBatchInfo.set(ProductInfoFields.MANUFACTURING_LICENSE_NUMBER, {
          ...prevBatchInfo.get(ProductInfoFields.MANUFACTURING_LICENSE_NUMBER)!,
            value: "No Manufacturing License Number"
          });

          // Set import license number to error message
        prevBatchInfo.set(ProductInfoFields.IMPORT_LICENSE_NUMBER, {
          ...prevBatchInfo.get(ProductInfoFields.IMPORT_LICENSE_NUMBER)!,
            value: "No Import License Number"
          });
        }

      return new Map(prevBatchInfo);
    });
  };

  useEffect(() => {
    console.log(
      "Powered by the Digimarc Illuminate Platform. Find out more at https://www.digimarc.com "
    );
    const currentTime = new Date();
    setPageLoadtime(currentTime);
    getThng();
  }, []);

  return (
    <HelmetProvider>
      <ThemeCustomization>
        <Helmet>
          <title>AstraZeneca - {config.pageTitle}</title>
        </Helmet>
        <Box
          id="app"
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Header pageTitle={config.pageTitle}></Header>

          <Container>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: 10,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && !error && (
              <>
                <Box sx={{ my: 5 }}>
                  <DataTable
                    title="Batch Information"
                    data={Array.from(batchInfoData.values())}
                    ariaLabel="Information Table about the current batch."
                  />
                </Box>

                {config.disclaimerMessage && (
                  <Typography sx={{ my: 5 }}>
                    {config.disclaimerMessage}
                  </Typography>
                )}
              </>
            )}

            {error && !loading && (
              <Alert sx={{ my: 5 }} severity="error">
                Something went wrong with the request to access this page{" "}
                {error}
              </Alert>
            )}
          </Container>

          <Footer>
            {!loading && (
              <DebugInfo
                debugInfo={{
                  accountId: {
                    label: "Account",
                    value: config.accountId,
                    errorMessage: "No Account Set",
                  },
                  thngId: {
                    label: "Thng",
                    value: thngId,
                    errorMessage: "",
                  },
                  productId: {
                    label: "Product",
                    value: productId,
                    errorMessage: "",
                  },
                  requestTime: {
                    label: "Page Load Time",
                    value: pageLoadTime ? formatDate(pageLoadTime) : "",
                    errorMessage: "",
                  },
                }}
              />
            )}

            <FooterContent
              companyName={config.companyName}
              year={config.year}
              version={config.version}
            />
          </Footer>
        </Box>
      </ThemeCustomization>
    </HelmetProvider>
  );
}

export default App;
