export enum ProductInfoFields {
  GTIN = "GTIN",
  PRODUCT_NAME = "Product Name",
  BRAND_NAME = "Brand Name",
  BATCH_ID = "Batch ID",
  EXPIRY_DATE = "Expiry Date",
  MANUFACTURING_DATE = "Manufacturing Date",
  MANUFACTURING_LICENSE_NUMBER = "Manufacturing License Number",
  IMPORT_LICENSE_NUMBER = "Import License Number",
  MANUFACTURING_NAME_ADDRESS = "Manufacturing Name/ Address",
}

export type ProductInfoValue = {
  name: ProductInfoFields;
  value: string;
  label: string;
  html: boolean;
};
