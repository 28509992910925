import { ProductInfoFields, ProductInfoValue } from "../types";

export const ProductCloudFieldNames = {
  [ProductInfoFields.MANUFACTURING_LICENSE_NUMBER]: "manufacturer-licence_number",
  [ProductInfoFields.MANUFACTURING_NAME_ADDRESS]: "manufacturer-address",
  [ProductInfoFields.IMPORT_LICENSE_NUMBER]: "import-licence_number",
  [ProductInfoFields.GTIN]: "gs1:01",
  [ProductInfoFields.BATCH_ID]: "gs1:10",
  [ProductInfoFields.MANUFACTURING_DATE]: "batch-manufacturer_date",
  [ProductInfoFields.EXPIRY_DATE]: "batch-expiry",
};

export const defaultDebugInfoMap: Map<ProductInfoFields, ProductInfoValue> =
  new Map([
    [
      ProductInfoFields.GTIN,
      {
        name: ProductInfoFields.GTIN,
        label: "Unique product identification code",
        value: "",
        html: false,
      },
    ],
    [
      ProductInfoFields.PRODUCT_NAME,
      {
        name: ProductInfoFields.PRODUCT_NAME,
        label: "Generic name of the drug",
        value: "",
        html: false,
      },
    ],
    [
      ProductInfoFields.BRAND_NAME,
      {
        name: ProductInfoFields.BRAND_NAME,
        label: "Brand name",
        value: "",
        html: false,
      },
    ],
    [
      ProductInfoFields.MANUFACTURING_NAME_ADDRESS,
      {
        name: ProductInfoFields.MANUFACTURING_NAME_ADDRESS,
        label: "Name and address of the manufacturer",
        value: "",
        html: true,
      },
    ],
    [
      ProductInfoFields.BATCH_ID,
      {
        name: ProductInfoFields.BATCH_ID,
        label: "Batch number",
        value: "",
        html: false,
      },
    ],
    [
      ProductInfoFields.MANUFACTURING_DATE,
      {
        name: ProductInfoFields.MANUFACTURING_DATE,
        label: "Date of manufacturing",
        value: "",
        html: false,
      },
    ],
    [
      ProductInfoFields.EXPIRY_DATE,
      {
        name: ProductInfoFields.EXPIRY_DATE,
        label: "Date of expiry",
        value: "",
        html: false,
      },
    ],

    [
      ProductInfoFields.MANUFACTURING_LICENSE_NUMBER,
      {
        name: ProductInfoFields.MANUFACTURING_LICENSE_NUMBER,
        label: "Manufacturing license number",
        value: "",
        html: false,
      },
    ],
    [
      ProductInfoFields.IMPORT_LICENSE_NUMBER,
      {
        name: ProductInfoFields.IMPORT_LICENSE_NUMBER,
        label: "Import license number",
        value: "",
        html: false,
      },
    ],
  ]);
