/* eslint-disable import-newlines/enforce */
import React from "react";

import { useTheme, Theme } from "@mui/material/styles";

import DOMPurify from "dompurify";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";

type DataRow = {
  name: string;
  value: any;
  label: string;
  html: boolean;
};

interface IDataTableProps {
  data: DataRow[];
  title: string;
  ariaLabel: string;
}

const allowedTags = [
  "b",
  "br",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
  "span",
  "strong",
  "em",
  "u",
  "s",
  "del",
  "sub",
  "sup",
  "blockquote",
  "code",
  "pre",
  "ul",
  "ol",
  "li",
  "strong",
  "em",
  "table",
  "tr",
  "td",
];

export default function DataTable({ title, data, ariaLabel }: IDataTableProps) {
  const theme: Theme = useTheme();

  const fullBorder = `1px solid ${theme.palette.primary.main}`;

  return (
    <TableContainer>
      <Table aria-label={ariaLabel}>
        <caption>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {title}
          </Box>
        </caption>
        <TableHead sx={{ border: "1px solid #d9338c" }}>
          <TableRow
            sx={{
              minWidth: 650,
              border: "1px solid #d9338c",
            }}
          >
            <TableCell
              colSpan={1}
              sx={{
                border: fullBorder,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              scope="col"
              id="name-header"
              align="center"
            >
              Name
            </TableCell>
            <TableCell
              colSpan={1}
              sx={{
                border: fullBorder,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
              scope="col"
              id="value-header"
              align="center"
            >
              Value
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: fullBorder }}>
          {data.filter((row) => String(row.value || "").length > 0).map((row) => (
            <TableRow key={row.name}>
              <TableCell
                colSpan={1}
                sx={{
                  borderStyle: "solid",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "0 0 1px 0",
                  width: "50%",
                }}
                component="th"
                scope="row"
                headers="name-header"
              >
                {row.label}
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{
                  borderStyle: "solid",
                  borderColor: theme.palette.primary.main,
                  borderWidth: "0 0 1px 1px",
                  color: theme.palette.primary.main,
                  width: "50%",
                }}
                headers="value-header"
              >
                {row.html && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(row.value, {
                        ALLOWED_TAGS: allowedTags,
                      }),
                    }}
                  ></div>
                )}
                {!row.html && row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
